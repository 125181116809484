<template>
  <v-app>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Rapor <b>Online</b></div>
    </b-alert>

    <div class="card">
      <div class="card-body">
        <!-- filter -->
        <div class="row justify-content-start mt-n3 mb-3">
          <div class="col-md-4" v-if="content == 'list'">
            <treeselect
              v-model="filter.class_group_id"
              :multiple="false"
              placeholder="Pilih Kelompok Kelas"
              :options="class_groups"
              @input="filterByClassGroup"
            />
          </div>
        </div>

        <b-tabs content-class="mt-3" @input="handleTab">
          <b-tab title="Rekap Nilai" v-if="recapValueView" active>
            <!-- <h2>Rekap Nilai</h2> -->
          </b-tab>
          <b-tab title="Rapor" v-if="raporOnlineView">
            <!-- <h2>Rapor</h2> -->
          </b-tab>
        </b-tabs>
        <v-slide-group
          v-model="model"
          class="pa-0"
          active-class="success"
          show-arrows
          v-if="content == 'list'"
        >
          <v-slide-item v-for="user in users" :key="user.id" v-slot="{ active, toggle }" @change="changeUser(user.id)">
            <v-card
              :color="active ? 'grey lighten-3' : 'transparent'"
              class="ma-3"
              height="100"
              width="100"
              elevation="0"
              @click="toggle"
            >
              <v-row class="fill-height" align="center" justify="center">
                <v-avatar>
                  <img
                    :src="user.photo"
                    :alt="user.name"
                  />
                </v-avatar>
                <h6 class="text-center">{{user.name}}</h6>
                <!-- <v-scale-transition>
                  <v-icon
                    v-if="active"
                    color="white"
                    size="48"
                    v-text="'mdi-close-circle-outline'"
                  ></v-icon>
                </v-scale-transition> -->
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>

        <template v-if="order_tab_active == 0 && recapValueView">
          <TableRecapValue />
        </template>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableRecapValue from "@/view/components/school-report-card/TableRecapValue";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    TableRecapValue,
  },
  data() {
    return {
      content: 'list',
      model: null,
      order_tab_active: 0,
      class_groups: [],
      users: [],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // filter
      filter:{
        class_group_id: '',
        user_id: ''
      },
      // access
      recapValueView: false,
      raporOnlineView: false,
      chartView: false
    };
  },
  methods: {
    handleTab(evt) {
      //("evt tab", evt);
      this.order_tab_active = evt;
    },
    async getUser() {
      let filterParams = `&class_group_id=${this.filter.class_group_id}&role_id=2`;
      let response = await module.paginate(
        "api/users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      this.users = response.data;
      //('user', this.users)
    },

    async getClassGroupOption() {
      let today = new Date().toISOString().substr(0, 10)
      let response = await module.setTreeSelect(
        `api/class-groups/available/my-class-groups/users/${getUser().id}`,
        `?now=${today}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.class_groups = response.data;
        this.class_groups.unshift({
          label: "Pilih Kelompok Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },
    async filterByClassGroup(evt) {
      if (!evt) {
        this.filter.class_group_id = await "";
        this.getUser();
      } else {
        this.filter.class_group_id = await evt;
        this.getUser();
        //("ok", this.filter.class_group_id);
      }
    },
    changeUser(user_id){
      this.filter.user_id = user_id
      //('filter', this.filter)
      this.$root.$emit('refreshTableRecapValue', this.filter)
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6001") {
          this.recapValueView = true
        }
        if (access_right[a] == "6002") {
          this.raporOnlineView = true
        }
        if (access_right[a] == "6003") {
          this.chartView = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Rapor Online" },
    ]);

    this.getUser();
    this.getClassGroupOption();
    this.checkAccess()

    this.$root.$on("handleContentRecapValue", (content) => {
      this.content = content
    });
  },
};
</script>

<style scoped>
</style>